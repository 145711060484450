import axios from 'axios'
const isAuthenticated = localStorage.getItem('token_auth');
export default axios.create({ 
//  baseURL: 'https://siembal.kjays.id/server/index.php/', 
baseURL : 'https://siembal.wbsantosa.com/server/index.php/',
  headers: {
    'Authorization': isAuthenticated,
    // "Content-Type": "multipart/form-data"

  }
})